import { useRef } from 'react';
import { useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';
import RoundIcon from 'components/common/RoundIcon';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import UserInfo from 'components/common/UserInfo';
import { track } from '@amplitude/analytics-browser';
import {
  coinNumberToString,
  useMediaQuery,
  useOutsideClick,
} from 'common/utils';
import NotificationIcon from './NotificationIcon';
import styles from './GlobalHeader.module.scss';
import LinkButton from 'components/common/LinkButton';
import GlobalSearchInput from './GlobalSearchInput';
import { useMemo } from 'react';
import { MissionTaskStatus } from 'service/user';
import { isIzanaviMember, isMembershipAccount } from 'common/utils/membership';
import {
  addEvent,
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
} from 'common/utils/pp_tracking';
import axios from 'axios';
import MembershipVipIcon from 'components/common/MembershipVipIcon';
import CharacterAvatar from './CharacterAvatar';

export default function GlobalHeader(): JSX.Element {
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const user = useStore((state) => state.currentUser);
  const allMissions = useStore((state) => state.allMissions);
  const refetchProfile = useStore((state) => state.refetchProfile);
  const missionPopupRef = useRef<HTMLDivElement>();
  const [clickedMissionsPopup, setClickedMissionsPopup] = useState(false);
  const missionsStatus = useMemo(() => {
    const inProgressMissionsCount = allMissions.filter(
      (ms) =>
        ms.current_task && ms.current_task.status !== MissionTaskStatus.Ready
    ).length;
    const hasReadyMission = allMissions.some(
      (ms) =>
        ms.current_task && ms.current_task.status === MissionTaskStatus.Ready
    );
    return {
      inProgressCount: inProgressMissionsCount,
      hasReadyMission: hasReadyMission,
    } as {
      inProgressCount: number;
      hasReadyMission: boolean;
    };
  }, [allMissions]);
  const [showDropdown, setShowDropdown] = useState(false);
  // const [keyword, setKeyword] = useState<string>();
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');
  const showCoinSummaryDialog = useGlobalStore(
    (state) => state.showCoinSummaryDialog
  );
  const showMissionDialog = useGlobalStore((state) => state.showMissionDialog);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const dropDownRef = useRef();
  useOutsideClick(dropDownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  const contentEl = (
    <ul className={styles.menuWrapper}>
      <div className={styles.userInfo}>
        <UserInfo user={user} avatarSize={58} hasUsername />
        <Link href="/account?tab=info">
          <a
            onClick={() => {
              setShowDropdown(false);
              track('Click Edit Profile Button');
            }}
            className={styles.updateProfile}
          >
            アカウント情報を編集
          </a>
        </Link>
      </div>
      {!isMobileScreen && (
        <Link href="/dashboard">
          <a className={styles.btn}>
            <li
              onClick={() => {
                setShowDropdown(false);
                track('Click Dashboard Button');
              }}
            >
              <Icon name="dashboard" width={24} height={24} />
              <span>ダッシュボード</span>
            </li>
          </a>
        </Link>
      )}
      <Link href="/account">
        <a className={styles.btn}>
          <li
            onClick={() => {
              setShowDropdown(false);
              track('Click Edit Profile Button');
            }}
          >
            <Icon name="settings" width={24} height={24} />
            <span>アカウントの管理</span>
          </li>
        </a>
      </Link>
      {isMobileScreen && (
        <Link href="/courses">
          <a className={styles.btn}>
            <li
              onClick={() => {
                setShowDropdown(false);
                track('Click Course Button');
              }}
            >
              <Icon name="course-nav" width={24} height={24} />
              <span>コース</span>
            </li>
          </a>
        </Link>
      )}
      {isSmallScreen && (
        <Link href="/trending_topics">
          <a className={styles.btn}>
            <li
              onClick={() => {
                setShowDropdown(false);
                track('Click Trend Button');
              }}
            >
              <Icon name="nav-trending" width={24} height={24} />
              <span>今の話題</span>
            </li>
          </a>
        </Link>
      )}
      {isMobileScreen && (
        <Link href="/creators/rankings">
          <a className={styles.btn}>
            <li
              onClick={() => {
                setShowDropdown(false);
                track('Click Ranking Button');
              }}
            >
              <Icon name="ranking" width={24} height={24} />
              <span>ランキング</span>
            </li>
          </a>
        </Link>
      )}
    </ul>
  );

  return (
    <>
      <div className={styles.header}>
        <div className={styles.container}>
          <div className={styles.firstLine}>
            {isSmallScreen ? (
              <div className={styles.linkWrapper}>
                <Link href="/">
                  <a
                    title="ホームページ"
                    onClick={() => {
                      track('Click Home Logo');
                    }}
                  >
                    <Image
                      src="/images/app-icon-1.png"
                      width={40}
                      height={40}
                      alt="PostPrime Logo"
                    />
                  </a>
                </Link>
              </div>
            ) : (
              <div className={styles.logo}>
                <Link href="/">
                  <a
                    title="ホームページ"
                    onClick={() => {
                      track('Click Home Logo');
                    }}
                  >
                    <Image
                      src="/images/logo.svg"
                      width={162}
                      height={23}
                      alt="PostPrime Logo"
                    />
                  </a>
                </Link>
              </div>
            )}
            <CharacterAvatar />
            <GlobalSearchInput />
            {currentAuth && user?.coin && (
              <div
                className={styles.coinWrapper}
                onClick={() => {
                  addEvent({
                    action: EventAction.Click,
                    category: EventCategory.Tutorial,
                    screen: ScreenName.HomeTimeline,
                    component: EventComponent.GlobalHeader,
                    timestamp: new Date().toISOString(),
                    tracking_params: [
                      {
                        label: 'click_position',
                        value: 'coin',
                      },
                    ],
                  });
                }}
              >
                <div
                  className={styles.left}
                  onClick={() => {
                    track('Click Coin Button');
                    showCoinSummaryDialog(true);
                  }}
                >
                  <Image src="/images/icons/coin.png" height={32} width={32} />
                  <span>{coinNumberToString(user?.coin?.sum)}</span>
                </div>
                <div
                  className={styles.right}
                  onClick={async (e) => {
                    const selection = window.getSelection();
                    if (
                      selection.type != 'Range' &&
                      !missionPopupRef?.current?.contains(
                        e.target as HTMLElement
                      )
                    ) {
                      if (user?.need_first_onboarding) {
                        try {
                          await axios.post(
                            `/users/on_boarding/complete_first_onboarding`,
                            {}
                          );
                          refetchProfile();
                          // eslint-disable-next-line no-empty
                        } catch (error) {}
                      }
                      track('Click Mission Button');
                      showMissionDialog(true);
                    }
                  }}
                >
                  {!isMobileScreen && (
                    <>
                      <div className={styles.blurOverlay}></div>
                      <span className={styles.text}>ミッション</span>
                    </>
                  )}
                  <div className={styles.number}>
                    <span className={styles.inprogressNumber}>
                      {missionsStatus.inProgressCount}
                    </span>
                    {missionsStatus.hasReadyMission && (
                      <div className={styles.hasReadyMission}></div>
                    )}
                    {user?.need_first_onboarding && (
                      <div
                        className={styles.missionsDialogPopover}
                        ref={missionPopupRef}
                        style={{
                          display:
                            user?.need_first_onboarding && !clickedMissionsPopup
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <span>コインをゲットする{'\n'}ミッションはこちら</span>
                        <button
                          onClick={async () => {
                            setClickedMissionsPopup(true);
                            try {
                              await axios
                                .post(
                                  `/users/on_boarding/complete_first_onboarding`,
                                  {}
                                )
                                .then(() => {
                                  refetchProfile();
                                });
                              // refetchProfile();
                              // eslint-disable-next-line no-empty
                            } catch (error) {}
                          }}
                        >
                          閉じる
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {currentAuth && user ? (
              <>
                {!isMobileScreen && (
                  <>
                    <Link href={`/${user.username}`}>
                      <a
                        onClick={() => {
                          track('Click Avatar Button');
                        }}
                      >
                        <div className={styles.avatarWrapper}>
                          <RoundIcon
                            user={user}
                            width={40}
                            height={40}
                            alt="avatar"
                            hasRing
                          />
                        </div>
                      </a>
                    </Link>
                  </>
                )}
                {(isMembershipAccount(user) || isIzanaviMember(user)) &&
                  !isMobileScreen && (
                    <div className={styles.membershipIcon}>
                      <MembershipVipIcon
                        width={60}
                        height={35}
                        packageName={
                          user?.membership?.membership_package?.package_name
                        }
                        isIzanavi={isIzanaviMember(user)}
                        onClick={() =>
                          setShowMembershipPayDialog({
                            open: true,
                            showMembershipStatus: false,
                            screen: ScreenName.HomeTimeline,
                          })
                        }
                      />
                    </div>
                  )}
                <NotificationIcon />
                <div className={styles.dropDownWrapper} ref={dropDownRef}>
                  <div
                    className={cn(styles.arrowWrapper, {
                      [styles.flip]: showDropdown,
                    })}
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <Icon name="down-arrow-circle" width={40} height={40} />
                  </div>
                  <div
                    className={styles.popperWrapper}
                    style={{ display: showDropdown ? 'block' : 'none' }}
                  >
                    {contentEl}
                  </div>
                </div>
              </>
            ) : (
              !currentAuth && (
                <>
                  <div className={styles.loginBtn}>
                    <Button
                      verticalPadding={6}
                      horizontalPadding={12}
                      text="新規登録"
                      onClick={() => {
                        showLogin({ showingTitle: 'signup' });
                      }}
                    />
                  </div>
                  <div className={styles.loginBtn}>
                    <LinkButton
                      text="ログイン"
                      type="normal"
                      onClick={() => {
                        showLogin({ showingTitle: 'login' });
                      }}
                      nonUnderline
                    />
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}
